.mainDl {
  height: 100vh;
  padding-top: 30vh;
}
.dbg {
  background-image: url("../../../Images/Delivery/bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center left;
}
.dataDX {
}
.Desc {
  width: 80%;
  margin: auto;
}
.Desc .col {
  padding-top: 150px;
}
.inWid {
  width: 70%;
  margin: auto;
}
.Desc .col h4 {
  color: #df2528;
  font-weight: bold;
  font-family: FontBold;
}
.dhd {
  font-family: FontBold;
  color: #df2528;
}
.exp {
  font-family: FontBold;
  color: #df2528;
}

.dNum {
  font-size: 150px;
  /* position: absolute; */
  font-family: FontBold;
  opacity: 0.006;
  width: 100%;
  /* background-color: antiquewhite; */
  text-align: center;
  /* z-index: -5; */
}
.NrmF {
  /* color: #2d438e; */
  font-family: "Assistant", sans-serif;
}
