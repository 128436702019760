@import url("https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overlock&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manjari&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@font-face {
  font-family: FontLight;
  src: url(./Gilroy-Light.otf);
}
@font-face {
  font-family: FontBold;

  font-family: "Manjari", sans-serif;
}

body {
  background: repeating-linear-gradient(
    90deg,
    #0000 0 calc((100% - 1 * 1px) / 6),
    rgb(211, 211, 227) 0 calc((100% - 1 * 1px) / 6 + 0.8px)
  );
  background-color: #e2e7ee;
  font-family: "Averia Serif Libre", cursive;
  font-family: FontLight;
  font-family: "Overlock", cursive;
  /* font-size: 16px; */
  overflow-x: hidden;
  /* background-image: url("../src/Images/Svg/line.svg"); */
  /* background-image: url("../src/Images/bg.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center left;
  color: #2d438e;
  font-family: "Assistant", sans-serif;
  font-family: "Manjari", sans-serif;
  /* transform: scale(.8); */
}
a {
  color: #2d438e;

  text-decoration: none;
}
.bixbutton {
  background: #df2528;
  color: white;
  border: none;
  border-radius: 10px;
}
.MainHd {
  background-image: url("../src/Images/Svg/line.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center left;
}

.floatHd {
  margin: 10vh;
  font-size: 250px;
  position: absolute;
  font-family: FontBold;
  opacity: 0.06;
  width: 100%;
  /* background-color: antiquewhite; */
  text-align: center;
  z-index: -5;
}
p {
  font-size: 22px;
}
@media only screen and (orientation: portrait) {
  body {
    font-size: 13px;
    overflow-x: hidden;
  }
  .floatHd {
    margin: 10vh;
    font-size: 130px;
    left: -10vw;
  }
  p {
    font-size: 14px;
  }
}
