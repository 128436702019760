* {
  box-sizing: border-box;
}
.header {
  overflow: hidden;
  padding: 20px 10px;
}

.header label {
  float: left;
  /* color: #2d438e; */
  text-align: center;
  padding: 12px;
  text-decoration: none;
  /* font-size: 18px; */
  line-height: 25px;
  border-radius: 4px;
}

.header label.logo {
  font-size: 25px;
  font-weight: bold;
  position: absolute;
  top: -30px;
  margin-left: -75px;
}

.header label:hover {
  /* color: black; */
}
.logohd {
  width: 320px;
}
.header label.active {
  background-color: dodgerblue;
  color: white;
  margin-left: 40px;
}

.header-right {
  float: right;
}
.hd {
  /* margin-left: 40px; */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.Logo {
  height: 200px;
}
.nav-item {
  margin: 10px;
  color: black;
  border-bottom: 3px solid #df2528;
}
a {
  color: #1a2856;
}
.navbar .navbar-brand {
  font-size: 30px;
}
.navbar .nav-item {
  padding: 10px 20px;
}
.navbar .nav-link {
  font-size: 20px;
  margin-left: 10px;
}
.fa-bars {
  color: #007bff;
  font-size: 30px;
}

@media only screen and (orientation: portrait) {
  .bg {
    background-color: white;
  }
  .fx {
  }
  .hd {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
  .logohd {
    width: 250px;
  }
}
