.ff svg {
  height: 23rem;
  margin-right: 4rem;
}
#envelope {
  animation: float 2s ease-in-out infinite;
}
#star1,
#star2,
#star3,
#star4,
#star5,
#star6 {
  animation: blink 1s ease-in-out infinite;
}
#star2 {
  animation-delay: 100ms;
}
#star3 {
  animation-delay: 500ms;
}
#star4 {
  animation-delay: 700ms;
}
#star5 {
  animation-delay: 300ms;
}
#star6 {
  animation-delay: 200ms;
}
@keyframes float {
  0% {
    trans.form: translateY(0px);
  }
  50% {
    trans.form: translateY(-20px);
  }
  100% {
    trans.form: translateY(0px);
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.form {
  min-width: 25rem;
  margin-bottom: 10vh;
}
.form .title {
  color: #212529;
  font-size: 2.5rem;
}
.form ..form-control {
  background-color: #f2f6f8;
  border-radius: 2rem;
  border: none;
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.11);
}
.form ..form-control.thick {
  height: 3.3rem;
  padding: 0.5rem 3.5rem;
}
 
.form ..form-control:focus {
  background-color: #f2f6f8;
  border: none;
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.11);
}
.form .message ..form-control {
  padding: 0.5rem 1.8rem;
}
.form ::placeholder {
  font-weight: 600;
  font-size: 1.1rem;
  color: #838788;
  position: relative;
  left: 0;
}
.form input,
.form textarea {
  color: #212529;
  font-size: 1.1rem;
}
.form .icon {
  color: #57565c;
  height: 1.3rem;
  position: absolute;
  left: 1.5rem;
  top: 1.1rem;
}
.btn.btn-primary {
  font-weight: bold;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 3rem;
  border: 0;
  border-radius: 3rem;
  /* background-image: linear-gradient(131deg, #ffd340, #ff923c, #ff923c, #ff923c); */
  background-size: 300% 100%;
  transition: all 0.3s ease-in-out;
}
.btn.btn-primary:hover:enabled {
  box-shadow: 0 0.5em 0.5em -0.4em #ff923c ba;
  background-size: 100% 100%;
  trans.form: translateY(-0.15em);
}
.direction ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  /* height: 20vh; */
  margin-bottom: 40px;
  text-align: center;
}

.direction ul li {
  flex: 1;
  margin: 10px;
  /* border: 1px solid grey; */
  background-color: white;
  box-shadow: 0px 0px 6px;
  padding: 20px;
}
.direction svg {
  margin: 40px;
  color: red;
}
.direction h2 {
  font-family: FontBold;
}
.ff{
  display: flex;
}
.cont{
  margin-top: 30vh;
}
@media only screen and (orientation: portrait) {
  .direction ul {
    display: block;
    /* margin-bottom: 100vh; */
  }
  .ff{
    display: block;
  }
 
}