.Services {
}
.Services ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.Services ul li {
  flex: 1;
  margin: 0;
  padding: 0;
  margin: 30px;
  /* box-shadow: 0px 0px 3px; */
  padding: 20px;
  background-color: white;
  /* height: 450px; */
  border-radius: 70px;
  color: #0c2269;
  text-align: center;
}
.Services ul li svg {
  margin-top: 40px;
  color: brown;
}
.Services ul li h2 {
  margin-top: 40px;
  color: #2d438e;
  font-family: FontBold;
}
.view {
  color: #df2528;
  font-weight: bold;
  text-align: right;
  padding-right: 30px;
}
.rm {
  display: block;
}
.mimg{

}
@media only screen and (orientation: portrait) {
  .Services ul {
    display: block;
    /* margin-bottom: 100vh; */
  }
  .mimg{
    position: absolute;
    opacity: 0.3;
  }
}
