.Ebg {
  /* background-image: url("../../../Images/Estate/bg.svg"); */
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center  ; */
  /* height: 350vh; */
}
.eMain {
  width: 40%;
  padding-top: 30vh;
}

.eMain h1 {
  font-family: FontBold;
  font-size: 30px;
}
.eMain p {
  /* font-family: FontBold; */
  font-size: 22px;
}
.MainX h1{
  font-size: 25px;
}

.bx1 {
  box-shadow: 0px 0px 4px;
  padding: 10px;
  border-radius: 10px;
  background-color: aliceblue;
  padding-bottom: 50px;
  margin: 10px;
  /* height: 400px; */
}
.bx1 h3 {
  font-weight: bold;
}
.mserv {
  font-size: 50px;
}
.bx1 h3{
  color:red;
  font-size: 18px;
}

@media only screen and (orientation: portrait) {
  .eMain {
    width: 60%;
    padding-top: 30vh;
    margin: auto;
    text-align: center;
  }
  .datapad {
    display: block;
  }
  .datapad  {
    display: block;
    width: 100%;
    /* background-color: antiquewhite; */
  }
  .eMain h1 {
  font-family: FontBold;
  font-size: 40px;
}
.eMain p {
  /* font-family: FontBold; */
  font-size: 14px;
}

}
