.about{
	cursor: pointer;
	/* background-color: #6B062D; */
	/* color: #FFFFFF; */
	padding-top: 20px;
	padding-bottom: 80px;
}
.about h1 {
	padding: 10px 0;
	margin-bottom: 20px;
    font-family: FontBold;
}
.about h2 {
	opacity: .8;
    font-weight: bold;
}
.about span {
	display: block;
	width: 100px;
	height: 100px;
	line-height: 100px;
	margin:auto;
	border-radius:50%;  
	font-size: 40px;
	/* color: #FFFFFF; */
	opacity: 0.7;
	background-color: #4C0822;
	border: 2px solid #4C0822;

	webkit-transition:all .5s ease;
 	moz-transition:all .5s ease;
 	os-transition:all .5s ease;
 	transition:all .5s ease;

}
.about-item:hover span{
	opacity: 1;	
	border: 2px solid #CC0039;
	font-size: 42px;
	-webkit-transform: scale(1.1,1.1) rotate(360deg) ;
	-moz-transform: scale(1.1,1.1) rotate(360deg) ;
	-o-transform: scale(1.1,1.1) rotate(360deg) ;
	transform: scale(1.1,1.1) rotate(360deg) ;
}
.about-item:hover h2{
	opacity: 1;
	-webkit-transform: scale(1.1,1.1)  ;
	-moz-transform: scale(1.1,1.1)  ;
	-o-transform: scale(1.1,1.1)  ;
	transform: scale(1.1,1.1) ;
}
.about .lead{
	/* color: #CC0039; */
	font-size: 14px;
	font-weight: bold;
}
