.Main {
  height: 60vh;
  margin-top: 20vh;
  align-items: center;
}
.Main h1 {
  font-size: 65px;
  font-family: FontBold;
  margin-bottom: 30px;
}
.MainX h1 {
  font-size: 35px;
  font-family: FontBold;
  /* margin-bottom: 30px; */
}
.daa {
  width: 60%;
}
@media only screen and (orientation: portrait) {
  .Main {
    height: auto;
    margin-top: 10vh;
    align-items: center;
    margin-bottom: 30px;
  }
  .Main h1 {
    font-size: 35px;
    font-family: FontBold;
    margin-bottom: 30px;
  }
  .daa {
    width: 90%;
    text-align: center;
  }
}
