.sub {
  /* margin-top: 70vh; */
  padding: 60px;
  background-color: #2d438e;
  /* margin-bottom: 10vh; */
  /* height: 350px; */
  /* border-radius: 100px; */
  color: white;
}
.sub h1 {
  font-size: 60px;
  font-family: FontBold;
}

.sub input {
  border: none;
  height: 60px;
  border-radius: 20px;
  padding: 20px;
  background-color: rgb(100, 100, 239);
  outline: none;
  color: white;
}
.sub input:nth-child(1) {
  width: 20%;
  margin-right: 20px;
}
.sub input:nth-child(2) {
  width: 30%;
  margin-right: 20px;
}
.sub button {
  border: none;
  height: 60px;
  /* border-radius: 20px; */
  padding-top: 10px;
  background: white;
  width: 20%;
  color: blue;
  font-weight: bold;
  margin: 0;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: lightgray;
  opacity: 1; /* Firefox */
}
.Fla {
  position: absolute;
  margin-left: 50%;
  margin-top: -10vh;
}
.dx {
  width: 70%;
  margin: auto;
}
.MainP h1 {
  font-size: 65px;
  font-family: FontBold;
  margin-bottom: 30px;
}
.Serbox {
  /* background: rgba(247, 182, 182, 0.5); */

  /* background-color: aliceblue; */
  /* height: 80vh; */
}
.msbg {
  background: rgba(255, 255, 255, 0.5);
}
.subdatdd {
  /* margin-top: 40vh; */
}
@media only screen and (orientation: portrait) {
  .sub {
    /* margin-top: 230vh; */
    width: 100%;
  }
  .dx {
    width: 100%;
  }

  .sub h1 {
    font-size: 18px;
  }
  .Fla img {
    position: absolute;
    height: 150px;
    margin-left: -60px;
  }
  .sub input:nth-child(1) {
    width: 40%;
    margin-right: 20px;
  }
  .sub input:nth-child(2) {
    width: 40%;
    margin-right: 20px;
  }
  .sub button {
    margin: 10px;
    width: 90%;
  }
  .subdatdd {
    /* margin-top: -30vh; */
  }
}
