.b1,
.b2 {
  /* width: 100%; */

  /* position: absolute; */
  /* height: 300px; */

  z-index: 10;
}
.sl1 {
  position: absolute;
  top: 0;
  width: 100%;

  height: 90vh;
  /* padding-top: 50%; */
  animation: zoom-out 12s linear infinite;
  z-index: 20;
}
.text-box {
  position: absolute;
  width: 100%;
  top: 35%;
  /* left: 5%; */
  /* color: white; */
  animation: textUp 12s linear infinite;
  z-index: 30;
  /* background-color: aliceblue; */
}
.xx {
  width: 60%;
}
.bg1x {
  background-image: url("../../../Images/Estate/bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.bg2 {
  background-image: url("../../../Images/Estate/bg2.svg");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}
.bg3 {
  background-image: url("../../../Images/Estate/bg3.svg");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}
/* @keyframes textUp {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(50px);
  }

  100% {
    transform: translateY(100px);
  }
} */

.text-box span {
}
.slide1 {
  animation: fade1 12s linear infinite;
}

.slide2 {
  animation: fade2 12s linear infinite;
}

.slide3 {
  animation: fade3 12s linear infinite;
}
@keyframes fade1 {
  0% {
    visibility: visible;
  }

  20% {
    visibility: hidden;
  }

  40% {
    visibility: hidden;
  }

  60% {
    visibility: hidden;
  }

  80% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}

@keyframes fade2 {
  0% {
    visibility: hidden;
  }

  20% {
    visibility: hidden;
  }

  40% {
    visibility: visible;
  }

  60% {
    visibility: hidden;
  }

  80% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
@keyframes fade3 {
  0% {
    visibility: hidden;
  }

  20% {
    visibility: hidden;
  }

  40% {
    visibility: hidden;
  }

  60% {
    visibility: hidden;
  }

  80% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}
@keyframes zoom-out {
  100% {
    width: 100%;
  }
}
