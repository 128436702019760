.Icons svg {
  margin: 20px;
}
.ft{
  /* margin-top:-80px; */
  background: rgba(253, 200, 200, 0.5);
  padding-top: 30px;

}

@media only screen and (orientation: portrait) {
  .Add {
    text-align: center;
  }
}
